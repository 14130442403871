;(function($){

    function Newsletter() {

        var _ = this;

        _.API = $("#app_url").val() + "/api/";

        var $newsletterForm = $("#newsletter-form").validate({
            rules: {
                newsletter_email: { required: true, email: true }
            },
            messages: {
                newsletter_email: { required: 'Informe o seu email', email: 'Ops, informe um email válido' }
            },
            invalidHandler: function(e) {
                swal({
                    title: "OPS!",
                    text: "Informe o e-mail.",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "OK!",
                    closeOnConfirm: true
                });

            },
            submitHandler: function( form ) {
                
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url:  _.API + "newsletter",
                    data: $( form ).serialize(),
                    success: function(result)
                    {
                        swal({
                            title: result.message,
                            text: "Click no botão para fechar!",
                            type: "success",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Fechar!",
                            closeOnConfirm: true
                        });

                        form.reset();
                    },
                    error: function(response) {
                        var errors = $.parseJSON(response.responseText);
                        
                        swal({
                            title: (errors.message) ? errors.message : "Ops! Houve um erro ao enviar. Tente novamente.",
                            text: "Click no botão para fechar!",
                            type: "warning",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Fechar!",
                            closeOnConfirm: true
                        });
                       
                        $newsletterForm.showErrors(errors);
            
                    }
                });
            }
        }); 
    }

   	new Newsletter();

}(jQuery));