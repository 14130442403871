;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	//_.store();
      _.partners();
      _.fullSlider();
    }

    Slides.prototype.store = function() {

        var html = '';

        $.get($('meta[name="store"]').attr('content')+'?route=api/featured', function( data) {
            $.each(data, function(index, value) {
                html +='<div class="item">' +
                    '<a href="'+value.href+'" target="_blank">'+
                        '<figure>'+
                            '<img src="'+value.thumb+'" alt="">'+
                        '</figure>'+
                        '<h1 class="title">'+value.name+'</h1>'+
                        '<h3 class="code">Cod: '+value.model+'</h3>'+
                        '<span class="btn-purchase"><i class="fa fa-shopping-cart" aria-hidden="true"></i> Comprar</span>'+
                    '</a>'+
                '</div>';
            });

             $(".section-store .carrossel").html(html);

            $('.section-store .carrossel').slick({
              dots: false,
              infinite: false,
              speed: 300,
              slidesToShow: 4,
              slidesToScroll: 4,
              nextArrow: '<button class="btn-slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
              prevArrow: '<button class="btn-slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
              autoplay: true,
              autoplaySpeed: 3000,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]   
            });
        })
        .fail(function() {
           $(".section-store").addClass('hidden');
        })
        .always(function() {  
            $(".section-store").removeClass('hidden');
            $('.section-store .carrossel').slick('reinit');
        }); 

    };

    Slides.prototype.partners = function() {
        $('.partners .carrossel').slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 7,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
          ]   
        });
    };

    Slides.prototype.fullSlider = function() {
        $('.full-slider').slick({
          fade: true,
          pauseOnFocus:false,
          pauseOnHover:false,
          dots: false,
          infinite: false,
          speed: 400,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
        });
    };
    
   	new Slides();

}(jQuery));
 
 
 
 
