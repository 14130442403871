;(function($){

    function Navigation() {
   	 	var _ =  this;

        $('#header-top a[href*="#"]:not([href="#"])').click(function() {
           
            var hash = this.hash, 
            target   = $(hash);
            target   = target.length ? target : $('[name=' + this.hash.slice(1) +']');

            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top - 130
              });
              return false;
            }            
        });
        
        var menuLeft       = document.getElementById( 'cbp-spmenu-s1' ),
        menuRight          = document.getElementById( 'cbp-spmenu-s2' ),
        showRightPush      = document.getElementById( 'showRightPush'),
        showRightPushClose = document.getElementById( 'showRightPushClose'),
        body               = document.body;

        showRightPush.onclick = function() {
            classie.toggle( this, 'active' );
            classie.toggle( body, 'cbp-spmenu-push-toleft' );
            classie.toggle( menuRight, 'cbp-spmenu-open' );
        };

        showRightPushClose.onclick = function() {
            classie.toggle( this, 'active' );
            classie.toggle( body, 'cbp-spmenu-push-toleft' );
            classie.toggle( menuRight, 'cbp-spmenu-open' );
        };

        $(document).on("click", ".nav-tab > li > a",  function(e){
            e.preventDefault();
            $(".nav-tab li a").removeClass('active');              
            $(".tabs .tab").addClass('hidden');

            $(this).addClass('active');
            $(document).find($(this).attr('href')).removeClass('hidden');
        });
    }

   	new Navigation();

}(jQuery));
 
 
 
 
